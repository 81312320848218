<template>
	<b-card>
		<!-- Title with Add Button -->
		<div class="d-flex justify-content-between text-primary">
			<!-- Title -->
			<b-card-title>Your Prescriptions</b-card-title>

			<!-- Add Button -->
			<b-card-title>
				<b-icon-plus-circle @click="onCreate()" />
			</b-card-title>
		</div>

		<!-- List of Prescription -->
		<template v-if="count > 0">
			<b-row>
				<b-card-group class="col-sm-6 col-md-4 col-xl-3" v-for="userPrescription in userPrescriptions" :key="userPrescription.id">
					<user-prescription-card :userPrescription="userPrescription" class="mb-3" />
				</b-card-group>
			</b-row>
		</template>
		<!-- No Prescription -->
		<template v-else>
			<b-alert show variant="info" class="d-flex justify-content-center align-items-center mt-3">
				<h4 class="alert-heading my-2">No prescriptions uploaded!</h4>
			</b-alert>
		</template>
	</b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserPrescriptionCard from "../../../../components/prescriptions/UserPrescriptionCard";

export default {
	components: { UserPrescriptionCard },
	created() {
		this.getPrescriptionsApi();
	},
	computed: {
		...mapGetters({
			userPrescriptions: "user/userPrescriptions/getPrescriptions",
		}),
		count() {
			return this.userPrescriptions.length;
		},
	},
	methods: {
		...mapActions({
			getPrescriptionsApi: "user/userPrescriptions/getPrescriptions",
		}),
		onCreate() {
			// redirect to form for new
			this.$router.push({ name: "UserPrescriptionForm" });
		},
	},
};
</script>

<style></style>
