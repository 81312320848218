<template>
	<div>
		<b-img v-bind="miniImageProps" :src="imageUrl" @click="showFullImageModal = true" class="fit cursor-pointer" alt="Prescription Image" />

		<!-- Full Image Modal -->
		<b-modal :title="name" size="md" v-model="showFullImageModal" hide-footer no-close-on-backdrop centered>
			<b-img-lazy v-bind="fullImageProps" :src="imageUrl" />
		</b-modal>
	</div>
</template>

<script>
export default {
	props: ["name", "imageUrl"],
	data() {
		return {
			miniImageProps: { width: 150, height: 150, center: true },
			fullImageProps: { width: 300, height: 300, fluid: true, center: true },
			showFullImageModal: false,
		};
	},
};
</script>
