<template>
	<b-card :title="prescription.name" body-class="d-flex flex-column" :border-variant="borderVariant">
		<!-- Prescription Image -->
		<prescription-image :name="prescription.name" :imageUrl="prescription.prescriptionImage.blobUrl" />

		<b-button @click.prevent="onDelete" type="submit" class="mt-2" variant="danger">
			Delete
		</b-button>
	</b-card>
</template>

<script>
import { mapActions } from "vuex";
import PrescriptionImage from "./PrescriptionImage";
export default {
	components: { PrescriptionImage },
	props: ["userPrescription", "borderVariant"],
	created() {
		this.prescription = { ...this.userPrescription };
	},
	data() {
		return {
			prescription: {
				id: null,
				name: null,
				prescriptionImage: null,
			},
		};
	},
	methods: {
		...mapActions({
			deletePrescription: "user/userPrescriptions/deletePrescription",
		}),
		onDelete() {
			this.deletePrescription(this.prescription.id);
		},
	},
};
</script>
